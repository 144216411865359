import moment from "moment";
import React from "react";
import { Address } from "../models/addressModel";
import { AddressType } from "../enums/AddressType";
import {
	getInvoicesFreightCosts,
	getInvoicesMaterialCosts,
	getInvoicesOtherCosts,
	getInvoicesTaxCosts,
	getPOTaxRate,
	getTaxCost,
	isHomeDepotPo,
} from "./PurchaseOrdertUtil";

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
	try {
		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

		const negativeSign = amount < 0 ? "-" : "";

		let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
		let j = i.length > 3 ? i.length % 3 : 0;

		return (
			negativeSign +
			(j ? i.substr(0, j) + thousands : "") +
			i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
			(decimalCount
				? decimal +
				  Math.abs(amount - parseInt(i))
						.toFixed(decimalCount)
						.slice(2)
				: "")
		);
	} catch (e) {
		console.log(e);
	}
};

export const priceFormatter = (cell, row?, rowIndex?, extraData?) => {
	if (cell <= 0) return;
	return (
		<>
			{cell > 0 && <span>$</span>} {formatMoney(cell)}
		</>
	);
};

export const materialCosts = (items, costView = 1) => {
	let materialCosts = 0;

	if (items) {
		items.forEach((item) => {
			materialCosts = materialCosts + materialCostForOneItem(item, costView);
		});
	}

	return materialCosts;
};

export const materialCostForOneItem = (item, costView = 1) => {
	let materialCost = 0;

	if (item) {
		const { quantity, unit_price, variant } = item;
		const { ordered, arrived, current, out } = quantity || {};

		let localOrdered = ordered;

		switch (costView) {
			case 2:
				localOrdered = ordered - arrived;
				break;
			case 3:
				localOrdered = arrived;
				break;
			case 4:
				localOrdered = current;
				break;
			case 5:
				localOrdered = out;
				break;
		}

		let price = 0;

		price = unit_price;

		materialCost = localOrdered * price;
	}

	return materialCost;
};

export const totalOrdered = (items) => {
	var totalOrdered = 0;
	items.forEach((item) => {
		try {
			const { quantity } = item;
			const { ordered } = quantity;

			totalOrdered = totalOrdered + ordered;
		} catch (e) {}
	});

	return totalOrdered;
};

export const formatDate = (date, time) => {
	if (!date) return "";

	if (time === true) {
		return moment(date).format("h:mm:ss a");
	}
	return moment(date).format("MM/DD/YY");
};

export const diffMinutes = (dt2, dt1) => {
	var date2 = new Date(dt2);
	var date1 = new Date(dt1);
	var diff = (date2.getTime() - date1.getTime()) / 1000;
	diff /= 60;
	return Math.abs(Math.round(diff));
};

export const isToday = (someDate) => {
	const today = new Date();
	if (!someDate) return false;
	someDate = new Date(someDate);
	const isToday = someDate.getDate() === today.getDate() && someDate.getMonth() === today.getMonth() && someDate.getFullYear() === today.getFullYear();

	return isToday;
};

export const formatAddress = (address) => {
	return address.street + " " + address.city + ",  " + address.state;
};

export const returnColors = (value, low, high, isBigGood) => {
	if (isBigGood) {
		if (value >= high) {
			return "green";
		} else if (value <= low) {
			return "danger";
		} else {
			return "warning";
		}
	} else {
		if (value >= high) {
			return "danger";
		} else if (value <= low) {
			return "green";
		} else {
			return "warning";
		}
	}
};

export const returnStatusClassColors = (status) => {
	const classes = {
		"text-info": status === "Processing" || status === "Inbound Staging" || status === "Outbound Staging",
		"text-warning": status === "Drying" || status === "Storage",
		"text-success": status === "Shipped",
	};
	return classes;
};

export const roundToDecimals = (num, decimals = 6) => {
	return +(Math.round(parseFloat(`${num}e+${decimals}`)) + "e-" + decimals);
};

export const formatAutoDescription = (autoDescription, unitOfMeasure = "Missing uom", withLineBreaks = false) => {
	let result = "";

	if (autoDescription) {
		if (typeof autoDescription === "string") {
			result = autoDescription;
		} else {
			// partialPalletBoxes
			const { quantityPerBox, partialPalletBoxes, palletCount, quantityPerPallet, boxesPerPallet, partialPalletQuantityPerBox, individual_value } =
				autoDescription;

			let tempPalletCount = parseFloat(palletCount);
			if (isNaN(tempPalletCount)) {
				tempPalletCount = 0;
			}

			if (partialPalletQuantityPerBox != null && tempPalletCount >= 1) {
				tempPalletCount -= 1;
			}

			let individualText = "";

			if (individual_value) {
				individualText = `\n${individual_value} individual units`;
			}

			if (quantityPerBox > 0) {
				const quantityPerPalletUnit = `${quantityPerPallet} ${unitOfMeasure}/plt (${boxesPerPallet} bxs/plt`;
				const quantityPerBoxUnit = `${quantityPerBox} ${unitOfMeasure}/bx`;
				if (partialPalletBoxes > 0) {
					const partialText = `1 partial: ${partialPalletBoxes} bxs @ ${quantityPerBoxUnit}`;

					if (tempPalletCount < 1) {
						result = `${partialText} ${individualText}`;
					}
					const pallets =
						Math.floor(tempPalletCount || 0) > 0
							? `${Math.floor(tempPalletCount || 0)} plts @ ${quantityPerPalletUnit} @ ${quantityPerBoxUnit}) `
							: "";
					result = `${pallets}${partialText} ${individualText}`;
				} else if (autoDescription.partialPalletQuantityPerBox > 0) {
					// SUPPORT LEGACY ITEMS
					const pallets = autoDescription.palletCount - 1 > 0 ? `${autoDescription.palletCount - 1} plts ` : "";
					result = `${pallets}@ ${autoDescription.quantityPerPallet} ${unitOfMeasure}/plt (${autoDescription.boxesPerPallet} bxs/plt @ ${autoDescription.quantityPerBox} ${unitOfMeasure}/bx) 1 partial: ${autoDescription.partialPalletQuantity} ${unitOfMeasure} (${autoDescription.partialPalletBoxes} bxs @ ${autoDescription.partialPalletQuantityPerBox} ${unitOfMeasure}/bx) ${individualText}`;
				} else if (autoDescription.partialPalletQuantity > 0) {
					// SUPPORT LEGACY ITEMS
					result = `${autoDescription.palletCount - 1} plts @ ${autoDescription.quantityPerPallet} ${unitOfMeasure}/plt (${
						autoDescription.boxesPerPallet
					} bxs/plt @ ${autoDescription.quantityPerBox} ${unitOfMeasure}/bx) 1 partial: ${
						autoDescription.partialPalletQuantity
					} ${unitOfMeasure} ${individualText}`;
				} else {
					if (palletCount !== null && palletCount > 0) {
						result = `${palletCount} plts @ ${quantityPerPalletUnit} @ ${quantityPerBoxUnit}) ${individualText}`;
					} else {
						result = `${individualText.replace("\n", "")}`;
					}
				}
			} else if (quantityPerPallet > 0) {
				if (autoDescription.partialPalletQuantity > 0) {
					result = `${palletCount - 1} plts @ ${quantityPerPallet} ${unitOfMeasure}/plt 1 partial: ${
						autoDescription.partialPalletQuantity
					} ${unitOfMeasure} ${individualText}`;
				} else {
					if (palletCount !== null && palletCount > 0) {
						result = `${palletCount} plts @ ${autoDescription.quantityPerPallet} ${unitOfMeasure}/plt ${individualText}`;
					} else {
						result = `${individualText.replace("\n", "")}`;
					}
				}
			} else if (palletCount > 0) {
				if (autoDescription.partialPalletQuantityPerBox > 0) {
					result = `${palletCount - 1} plts 1 partial: ${autoDescription.partialPalletQuantity} ${unitOfMeasure} (${partialPalletBoxes} bxs @ ${
						autoDescription.partialPalletQuantityPerBox
					} ${unitOfMeasure}/bx) ${individualText}`;
				} else if (autoDescription.partialPalletQuantity > 0) {
					result = `${palletCount - 1} plts 1 partial: ${autoDescription.partialPalletQuantity} ${unitOfMeasure} ${individualText}`;
				} else {
					result = `${palletCount} plts ${individualText}`;
				}
			}
		}
	}

	if (withLineBreaks) {
		return result.split("\n").map((element, index) => (
			<>
				{index > 0 && <br />}
				{element}
			</>
		));
	} else {
		return result;
	}
};

export const formatDateYYYYMMDD = (date) => {
	const offset = date.getTimezoneOffset();
	date = new Date(date.getTime() - offset * 60 * 1000);
	return date.toISOString().split("T")[0];
};

export const getForDataFromObject = (obj: any) => {
	if (typeof obj !== "object") return undefined;
	const formData = new FormData();
	Object.entries(obj).forEach(([key, value]: any) => {
		formData.append(key, value);
	});
	return formData;
};

export const sortLocations = (addressList: Address[]): Address[] => {
	if (!addressList) return [];
	return addressList.sort((addressA, addressB) => {
		if (addressA.type === addressB.type) return addressA.name.localeCompare(addressB.name);
		else if ([addressA.type, addressB.type].includes(AddressType.JOB_SITE)) return addressA.type === AddressType.JOB_SITE ? -1 : 1;
		else return addressB.type.localeCompare(addressA.type);
	});
};

// export const getItemCosts = (po, item, costView = 1) => {
// 	let materialCostValue = 0;
// 	let freightCost = 0;
// 	let taxCost = 0;
// 	let otherCost = 0;
//
// 	if (isHomeDepotPo(item)) {
// 		materialCostValue += getInvoicesMaterialCosts(item.invoices);
// 		freightCost += getInvoicesFreightCosts(item.invoices);
// 		taxCost += getInvoicesTaxCosts(item.invoices);
// 		otherCost += getInvoicesOtherCosts(item.invoices);
// 	} else {
// 		const actMaterialCosts = materialCosts(item.inventory_items, costView);
// 		const ratioNumber = isNaN(actMaterialCosts / materialCosts(item.inventory_items, 1)) ? 0 : actMaterialCosts / materialCosts(item.inventory_items, 1);
//
// 		materialCostValue += actMaterialCosts;
// 		freightCost += item.deliveryCost * ratioNumber;
// 		taxCost += getTaxCost(item, costView, ratioNumber);
// 		otherCost += item.otherCost * ratioNumber;
// 	}
//
// 	return {
// 		materialCost: roundToDecimals(materialCostValue || 0, 2),
// 		freightCost: roundToDecimals(freightCost || 0, 2),
// 		taxCost: roundToDecimals(taxCost || 0, 2),
// 		otherCost: roundToDecimals(otherCost || 0, 2),
// 		totalCost: roundToDecimals(materialCostValue + freightCost + taxCost + otherCost || 0, 2),
// 	};
// };

// export const getItemCosts = (po, item, costView = 1) => {
// 	const actMaterialCosts = materialCosts(po.inventory_items, costView);
// 	const ratioNumber = isNaN(actMaterialCosts / materialCosts(po.inventory_items, 1)) ? 0 : actMaterialCosts / materialCosts(po.inventory_items, 1);
//
// 	const materialCostValue = materialCostForOneItem(item, costView);
// 	const freightCost = (item.freightCost ?? 0) * ratioNumber;
// 	const taxCost = getPOTaxRate(purchaseOrder)item.taxCost ?? 0;
// 	const otherCost = (item?.otherCost ?? 0) * ratioNumber;
//
// 	return {
// 		materialCost: roundToDecimals(materialCostValue || 0, 2),
// 		freightCost: roundToDecimals(freightCost || 0, 2),
// 		taxCost: roundToDecimals(taxCost || 0, 2),
// 		otherCost: roundToDecimals(otherCost || 0, 2),
// 		totalCost: roundToDecimals(materialCostValue + freightCost + taxCost + otherCost || 0, 2),
// 	};
// };

export const getInvoiceCosts = (invoice, costView = 1) => {
	const materialCostValue = invoice.materialCost ?? 0;
	const freightCost = invoice.freightCost ?? 0;
	const taxCost = invoice.taxCost ?? 0;
	const otherCost = invoice?.otherCost ?? 0;

	return {
		materialCost: roundToDecimals(materialCostValue || 0, 2),
		freightCost: roundToDecimals(freightCost || 0, 2),
		taxCost: roundToDecimals(taxCost || 0, 2),
		otherCost: roundToDecimals(otherCost || 0, 2),
		totalCost: roundToDecimals(materialCostValue + freightCost + taxCost + otherCost || 0, 2),
	};
};
