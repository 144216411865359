import React from "react";

import { formatMoney, isToday } from "./functions";
import ItemPreview, { getItemTextPreview } from "../pages/itemBank/createItem/ItemPreview";
import classNames from "classnames";
import Moment from "react-moment";
import { getInvoiceTotalCosts, getInvoicesMaterialCosts, getTaxCost, isHomeDepotPo } from "./PurchaseOrdertUtil";
import { getProjectName } from "./ProjectUtil";
import TooltipItem from "../components/TooltipItem";

export const getMaterialCosts = (items) => {
	let materialCosts = 0;

	if (items) {
		items.map((item) => {
			const { quantity, unit_price, variant } = item;
			const { ordered } = quantity;

			let price = 0;

			// variant && variant.price && variant.price > 0 ? price = variant.price : unit_price && unit_price > 0 ? price = unit_price : undefined;
			price = unit_price;

			materialCosts = materialCosts + ordered * price;
		});
	}

	return materialCosts;
};

export const getTotalCosts = (row) => {
	const { inventory_items, invoices } = row;

	if (isHomeDepotPo(row)) {
		return invoices?.reduce((sum, invoice) => sum + getInvoiceTotalCosts(invoice), 0);
	} else {
		let materialCosts = getMaterialCosts(inventory_items);

		const { otherCost, deliveryCost } = row;

		return materialCosts + otherCost + deliveryCost + getTaxCost(row);
	}
};

export const totalCostsColumn = (row) => {
	if (!row) return `0.00`;

	const value = formatMoney(getTotalCosts(row) || 0);

	return `$${value}`;
};

export const costsColumn = (value) => {
	return `$${formatMoney(value || 0)}`;
};

export const taxCostsColumn = (row) => {
	return `$${formatMoney(getTaxCost(row) || 0)}`;
};

export const materialCostsColumn = (row) => {
	if (!row) return `0.00`;

	const { inventory_items, invoices } = row;

	let materialCosts = isHomeDepotPo(row) ? getInvoicesMaterialCosts(invoices) : getMaterialCosts(inventory_items);

	const value = formatMoney(materialCosts || 0);
	return `$${value}`;
};

export const itemDescriptionColumn = (row, simple = true, linkTo = undefined, showFinishCode = false) => {
	if (row?.associated_item_bank) {
		if (row.associated_item_bank.deleted_at) return getItemTextPreview(row);
		let data = row.associated_item_bank;
		if (row.variant) {
			const {
				price,
				size,
				vendor,
				manufacturer_code,
				vendor_code,
				sub_description,
				finish,
				color,
				createdBy,
				modifiedBy,
				createdAt,
				updatedAt,
				description,
			} = row.variant;
			data = {
				manufacturer: row?.associated_item_bank?.manufacturer,
				manufacturer_codes: manufacturer_code ? [manufacturer_code] : [],
				vendors: vendor ? [vendor] : [],
				vendor_codes: vendor_code ? [vendor_code] : [],
				descriptions: row.variant?.descriptions || row.associated_item_bank?.descriptions || [],
				description: description,
				custom_fields: row.variant?.custom_field ? [row.variant.custom_field] : [],
				sub_descriptions: sub_description ? [sub_description] : [],
				finishes: finish ? [finish] : [],
				colors: color ? [color] : [],
				sizes: size ? [size] : [],
				prices: price ? [price] : [],
				createdBy: createdBy,
				modifiedBy: modifiedBy,
				createdAt: createdAt,
				updatedAt: updatedAt,
				finish_code: row.finish_code,
			};
		}
		return (
			<ItemPreview
				data={data}
				showFieldLabel
				hiddenFields={["vendors", "manufacturer"]}
				maxWidth={"max-content"}
				simple={simple}
				linkTo={linkTo}
				handleOnClick={() => {}}
				showFinishCode={showFinishCode}
			/>
		);
	}

	return `${row?.item_code || "-"}`;
};

export const itemDescription = (row, hasLink = true, showFinishCode = false) => {
	if (!!row.associated_item_bank?.deletedAt) return getItemTextPreview(row);
	if (!!row.assigned_variant || !row.assigned_variant?.createdAt || !row.assigned_variant?.item_bank)
		return itemDescriptionColumn(row, true, hasLink ? `/items/${row._id}` : undefined, showFinishCode);

	let assigned_variant = {
		...row.assigned_variant,
		associated_item_bank: {
			...row.assigned_variant.item_bank,
		},
		variant: row.assigned_variant.item_bank?.variants.find((elem) => elem._id === row.assigned_variant._id),
	};

	const assignedDesc = getItemTextPreview(assigned_variant);
	const description = itemDescriptionColumn(row, true, hasLink ? `/items/${row._id}` : undefined, showFinishCode);
	return <TooltipItem itemText={description} tooltipText={assignedDesc} placement={"right"} id={row.assigned_variant._id} />;
};

export const manufacturerColumn = (row) => {
	if (row.associated_item_bank?.manufacturer?.name) {
		return row.associated_item_bank.manufacturer.name;
	}

	return row.manufacturer || "-";
};

export const vendorColumn = (row) => {
	if (row?.variant?.vendor?.name) {
		return row.variant.vendor.name;
	} else if (row?.vendor?.name) {
		return row.vendor.name;
	} else if (row?.associated_item_bank?.manufacturer?.name) {
		return row.associated_item_bank.manufacturer.name;
	}

	return row?.manufacturer || "-";
};

export const totalQuantityColumn = (quantityValue) => {
	return `${formatMoney(quantityValue || 0)}`;
};

export const priceColumn = (row) => {
	if (row === "Multiple") {
		return row;
	}

	return `$${formatMoney(row || 0)}`;
};

export const totalCostColumn = (row) => {
	return `$${formatMoney((row?.quantity?.ordered || 0) * (row?.unit_price || 0))}`;
};

export const shouldArriveDateColumn = (row) => {
	if (!row.estimated_delivery_date) return "";

	const isLate = row.quantity.arrived < 1 && new Date(row.estimated_delivery_date) <= new Date();

	return (
		<React.Fragment>
			<h5>
				<span
					className={classNames("badge", {
						"badge-danger-lighten": isLate,
						// 'badge-success-lighten': isToday(cell),
					})}>
					<Moment format="MM/DD/YY">{row.estimated_delivery_date}</Moment>
				</span>
			</h5>
		</React.Fragment>
	);
};

export const dateColumn = (dateValue) => {
	if (!dateValue) return "";

	return (
		<React.Fragment>
			<h5>
				<span
					className={classNames("badge", {
						// 'badge-success-lighten': false,
						"badge-success-lighten": isToday(dateValue),
					})}>
					<Moment format="MM/DD/YY">{dateValue}</Moment>
				</span>
			</h5>
		</React.Fragment>
	);
};

export const outstandingQuantityColumn = (row) => {
	return `${formatMoney((row?.quantity?.ordered || 0) - (row?.quantity?.arrived || 0))}`;
};

export const outstandingAdjustmentQuantityColumn = (row) => {
	return `${formatMoney((row?.new_inventory_levels?.ordered || 0) - (row?.new_inventory_levels?.arrived || 0))}`;
};

export const projectNameColumn = (row) => {
	if (row.associated_job) {
		return getProjectName(row.associated_job);
	}
	if (row.project) {
		return getProjectName(row.project);
	}
	return getProjectName(row);
};

export const projectVendorColumn = (row) => {
	const inventory_items_vendors = row.inventory_items.map((item) => item?.variant?.name).filter((value) => value != null);
	const purchase_orders_vendors = row.purchase_orders.map(({ vendor }) => vendor).filter((value) => value != null);
	const vendors = new Set(inventory_items_vendors.concat(purchase_orders_vendors));
	return vendors.size === 1 ? [...vendors][0] : vendors.size > 1 ? "Multiple" : "-";
};

export const uomColumn = (row) => {
	if (!row) return "";

	let uom = row.variant ? row.variant.uom : undefined;

	return uom ? uom : "";
};
